import { format, distanceInWords, differenceInDays } from 'date-fns'
import React from 'react'
import { Link } from 'gatsby'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import BlockContent from './block-content'
import Container from './container'
import RoleList from './role-list'

import styles from './project.module.css'

function Project (props) {
  const { _rawBody, title, categories, mainImage, members, publishedAt, relatedProjects, squareFootage, bedrooms, bathrooms, listingAgent, homeType, lotFootage, pricePerSquareFoot, yearBuilt, matterportLink, gallery } = props
  return (
    <article className={styles.root}>
      {/* {props.mainImage && mainImage.asset && (
        <div className={styles.mainImage}>
          <img
            src={imageUrlFor(buildImageObj(mainImage))
              .width(1200)
              .height(Math.floor((9 / 16) * 1200))
              .fit('crop')
              .url()}
            alt={mainImage.alt}
          />
        </div>
      )} */}
      <p align="center"><h1 className={styles.title}>{title}</h1></p>
      <h1 className={styles.header}>Virtual Tour</h1>
      <hr className={styles.break}></hr>
      <p align="center"><iframe width='1200' height='600' src={matterportLink} fit='crop' frameborder='0' allowfullscreen allow='xr-spatial-tracking'></iframe></p>
      <h1 className={styles.header}>Gallery</h1>
      <hr className={styles.break}></hr>
      <p align="center">
        <ul className={styles.images}>
          {gallery.map(photo => ( 
              <li className={styles.gallery}><img
                src={imageUrlFor(buildImageObj(photo))
                  .width(800)
                  .height(500)
                  .fit('crop')
                  .url()}/>
                  <br></br>
              </li>
          ))}
        </ul>
      </p>
      <h1 className={styles.header}>Home Details</h1>
      <hr className={styles.break}></hr>
      <p align="center">
        <ul className={styles.homeInfo}>
            <li>Square Feet: {squareFootage}</li>
            <li>Bedrooms: {bedrooms}</li>
            <li>Bathrooms: {bathrooms}</li>
            <li>Listing Agent: {listingAgent}</li>
            <li>Home Type: {homeType}</li>
            <li>Lot Footage: {lotFootage}</li>
            <li>Price per Square Foot: {pricePerSquareFoot}</li>
            <li>Year Built: {yearBuilt}</li>
        </ul>
      </p>
      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            {_rawBody && <BlockContent blocks={_rawBody || []} />}
          </div>
          {/* <aside className={styles.metaContent}>
            {publishedAt && (
              <div className={styles.publishedAt}>
                {differenceInDays(new Date(publishedAt), new Date()) > 3
                  ? distanceInWords(new Date(publishedAt), new Date())
                  : format(new Date(publishedAt), 'MMMM Do YYYY')}
              </div>
            )}
            {members && <RoleList items={members} title='Authors' />}
            {categories && (
              <div className={styles.categories}>
                <h3 className={styles.categoriesHeadline}>Categories</h3>
                <ul>
                  {categories.map(category => (
                    <li key={category._id}>{category.title}</li>
                  ))}
                </ul>
              </div>
            )}
            {relatedProjects && (
              <div className={styles.relatedProjects}>
                <h3 className={styles.relatedProjectsHeadline}>Related projects</h3>
                <ul>
                  {relatedProjects.map(project => (
                    <li key={`related_${project._id}`}>
                      <Link to={`/project/${project.slug.current}`}>{project.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </aside> */}
        </div>
      </Container>
    </article>
  )
}

export default Project
